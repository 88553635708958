<template>
    <div class="container-fluid book">
        <div class="my-2 d-flex flex-wrap bg-light py-2 sticky-top small" v-if="id" style="top: 56px;">
            <div v-if="!readable" class="mx-2 mb-2 d-flex flex-wrap" style="width: 8rem;">
                <label class="align-self-center me-2">Chapter:&nbsp;</label>
                <select v-model="selectedChapter" class="align-self-center text-end form-control form-control-sm" style="max-width: 4rem;">
                    <option v-for="(item,index) in chapters" :key="index">{{item}}</option>
                </select>
            </div>
            <button type="button" :class="{'btn btn-sm mb-2 me-2 align-self-center':true, 'btn-primary': kjv, 'btn-outline-primary': !kjv}" @click="readable=false;kjv=true;originaltextview=false;myview=false;strongview=false;plain=false">KJV</button>
            <button type="button" :class="{'btn btn-sm mb-2 me-2 align-self-center':true, 'btn-primary': originaltextview, 'btn-outline-primary': !originaltextview}" @click="readable=false;kjv=true;originaltextview=true;myview=false;strongview=false;plain=false">Original Text View</button>
            <button type="button" :class="{'btn btn-sm mb-2 me-2 align-self-center':true, 'btn-primary': strongview, 'btn-outline-primary': !strongview}" @click="readable=false;kjv=false;originaltextview=false;myview=true;strongview=true;plain=false">My View/Strongs</button>
            <span v-if="myview">
                <button type="button" :class="{'btn btn-sm mb-2 me-2 align-self-center':true, 'btn-primary': plain, 'btn-outline-primary': !plain}" @click="readable=false;kjv=false;originaltextview=false;myview=true;strongview=false;plain=true">My View/Plain</button>
                <button v-if="plain" type="button" :class="{'btn btn-sm mb-2 me-2 align-self-center':true, 'btn-primary': !custommarked, 'btn-outline-primary': custommarked}" @click="custommarked=!custommarked">Toggle Custom Words Marking</button>
            </span>
        </div>
        <table :class="{'table table-sm table-bordered':!readable}">
            <thead v-if="!readable">
                <tr class="text-center fw-bold">
                    <th>Verse</th>
                    <th>Text</th>
                </tr>
            </thead>
            <tbody ref="tbody" @click="popDefinition">
                <tr v-for="item in recs" :key="item.verse" :id="'verse_'+item.verse.replace(':','_')">
                    <td valign="top"><div class="text-end me-2">{{item.verse}}</div></td>
                    <td><div :class="{'kjv': kjv, 'originaltextview': originaltextview, 'myview': myview, 'strongview': strongview, 'plain': plain, 'custommarked': custommarked}" v-html="addsuprefs(item.text, id)"></div></td>
                </tr>
            </tbody>
        </table>
        <modal name="mdlDefinition" :adaptive="true" :resizable="false" :reset="true" :clickToClose="false" height="auto" :scrollable="true" width="80%">
            <div class="p-2">
                <definition-vue :id="referenceid" @closed="closeDefinition"></definition-vue>
            </div>
        </modal>
    </div>
</template>
<style>
    .refStrong {
        display: none;
    }

    .originaltextview .refStrong {
        --bs-bg-opacity: 1;
        background-color: rgba(var(--bs-success-rgb),var(--bs-bg-opacity)) !important;
        display: inline-block;
        padding: .35em .65em;
        font-size: .75em;
        font-weight: 700;
        line-height: 1;
        color: #fff;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: .25rem;
    }

    sup.detail {
        cursor: pointer;
        color: blue;
    }

    .originaltextview.myview .striked {
        text-decoration: line-through;
        --bs-bg-opacity: 1;
        background-color: rgba(var(--bs-warning-rgb),var(--bs-bg-opacity)) !important;
        display: none;
        padding: .35em .65em;
        font-size: .75em;
        font-weight: 700;
        line-height: 1;
        color: #fff;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: .25rem;
    }

    .strongview .replaced {
        --bs-bg-opacity: 1;
        background-color: rgba(var(--bs-info-rgb),var(--bs-bg-opacity)) !important;
        display: inline-block;
        padding: .35em .65em;
        font-size: .75em;
        font-weight: 700;
        line-height: 1;
        color: #fff;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: .25rem;
    }

    .plain.custommarked .replaced {
        text-decoration: underline;
    }

    .plain .striked {
        display: none;
    }

    .plain .replaced {
        display: inline-block;
    }

    .readable.marked .replaced {
        text-decoration: underline;
    }

    .plain sup {
        display: none;
    }

    .kjv .striked {
        display: inline-block;
    }

    .kjv .replaced {
        display: none;
    }

    .originaltextview .refword {
        text-decoration: line-through;
        --bs-bg-opacity: 1;
        background-color: rgba(var(--bs-warning-rgb),var(--bs-bg-opacity)) !important;
        padding: .35em .65em;
        font-size: .75em;
        font-weight: 700;
        line-height: 1;
        color: #fff;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: .25rem;
    }

    .btn, .nav-link, .dropdown-item {
        text-transform: none !important;
    }
</style>
<script>
    import DefinitionVue from './Definition.vue';
    const _ = require('lodash');

    export default {
        props: {
            id: String,
            verse: String,
        },
        components: {
            DefinitionVue
        },
        computed: {
            prefix: (vm) => +vm.id < 40 ? "H" : "G",
        },
        data() {
            return {
                recs: [],
                referenceid: null,
                customs: {},
                strongs: {},
                readable: false,
                kjv: false,
                originaltextview: false,
                myview: false,
                strongview: false,
                plain: false,
                custommarked: false,
                chapters: [],
                selectedChapter: 1,
                hebrews: [],
                greeks: [],
            };
        },
        methods: {
            closeDefinition() {
                const vm = this;
                vm.referenceid = null,
                    vm.$modal.hide('mdlDefinition');
                vm.loadCustoms();
            },
            popDefinition(e) {
                if (e.target.matches('sup.detail')) {
                    const vm = this;
                    vm.referenceid = e.target.dataset["referenceid"];
                    vm.$modal.show('mdlDefinition');
                }
            },
            async loadBook(id) {
                const vm = this;
                vm.recs = [];
                vm.chapters = [];
                vm.selectedChapter = 1;
                const lines = _.split((await (await fetch(`/res/${id}.txt`)).text()), '\n');
                for (var i = 0; i < lines.length; i++) {
                    if (!(lines[i] && lines[i].length)) return;
                    var vals = _.split(lines[i], '\t');
                    if (!(vals && vals.length >= 3)) return;
                    vm.recs.push({ book: vals[0], verse: vals[1], text: vals[2] });
                    const chapter = _.split(vals[1], ':')[0];
                    if (!~vm.chapters.indexOf(chapter)) vm.chapters.push(chapter);
                }
            },
            loadCustoms() {
                const vm = this;
                _.map(_.split(localStorage.getItem('customs'), /\\n|\s/).filter(x => x.startsWith(vm.prefix)), x => { return vm.customs[x.split(',')[0]] = x.split(',')[1]; });
            },
            async loadStrongs() {
                const vm = this;
                const resp = await fetch(vm.prefix === 'H' ? `/res/dictheb.txt` : `/res/dictgreek.txt`);
                const txts = await resp.text();
                const txtsArray = _.split(txts, /[\r\n]+/).filter(x => x && x.length);
                _.map(txtsArray, x => { return vm.strongs[`${vm.prefix}${x.split(',')[0]}`] = x.split(',')[1]; });
            },
            addsuprefs(val, id) {
                const vm = this;
                const prefix = +id < 40 ? "H" : "G";
                let outVal = val.replace(/\S+\(?\d+\)?/gi, m => {
                    const m1 = m.replace(/[\D()]/gi, '');
                    const m2 = m.replace(/\(?\d+\)?/gi, '')
                    const _ref = `${prefix}${m1}`;
                    let pre = vm.customs[_ref] ? (m2 && m2.length ? `<span class='striked refword'>${m2}</span>` : ``) + `<span class='replaced'>${vm.customs[_ref]}</span>` :
                        (m2 && m2.length ? `<span class='refword'>${m2}</span>` : ``);
                    pre += `<span class='refStrong'>${vm.strongs[_ref]}</span>`;
                    return `${pre}<sup class='detail' data-referenceid='${_ref}'>${_ref}</sup>`
                });
                return outVal;
            },
        },
        watch: {
            id: {
                immediate: true,
                async handler(nv, ov) {
                    if (nv === ov || !nv) return;
                    const vm = this;
                    [vm.readable, vm.kjv, vm.originaltextview, vm.myview, vm.strongview, vm.plain, vm.custommarked] = [false, true, false, false, false, false, true];
                    vm.loadCustoms();
                    await vm.loadStrongs();
                    await vm.loadBook(nv);
                }
            },
            verse: {
                immediate: true,
                handler(nv, ov) {
                    if (nv == ov || !nv) return;
                    if (nv === '1_1') return window.scrollTo({ top: 0, behavior: 'smooth' });
                    const vm = this;
                    vm.selectedChapter = _.split(nv, '_')[0];
                    setTimeout(() => {
                        const el = document.querySelector(`#verse_${nv}`);
                        const y = el.getBoundingClientRect().top + window.pageYOffset - 110;
                        window.scrollTo({ top: y, behavior: 'smooth' });
                    }, 1000);
                }
            },
            selectedChapter: {
                immediate: false,
                handler(nv, ov) {
                    if (nv == ov || !nv) return;
                    const vm = this;
                    vm.$router.push({ path: `/Book/${vm.id}/${nv}_1` });
                }
            }
        }
    }
</script>